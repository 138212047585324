.hcontact{
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    background: linear-gradient(90deg,#ffaf3d 50%, #283550  50%);
    object-fit:cover !important;
    background-repeat: no-repeat;
}
.hcontact{
    padding-left: 100px;
    padding-right: 100px;
}
.submit_btn{
    background-color: #ffaf3d;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
}
.bnts{
    padding-top: 20px;
}
.bnts button{
    color: white;
    font-weight: bold;
}
.bnts button:hover{
    color: white;
    font-weight: bold;
}
.quoteform{
    padding-left: 50px;
}
.quote{
    color: white;
}

@media (max-width:900px){
    .hcontact{
        padding-left: 12px;
        padding-right: 12px;
    }
    .quoteform{
        padding-left: 0px;
    }
    .hcontact{
        padding-top: 10px;
        padding-bottom: 20px;
        width: 100%;
        background: linear-gradient(180deg,#ffaf3d 40%, #283550  60%);
        object-fit:cover !important;
        background-repeat: no-repeat;
    }
}