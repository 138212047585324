.abts{
    padding-left: 30px;
    padding-right: 30px;
}
.abtbtn{
    background-color: #283550;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    padding: 12px 30px;
    border-radius: 100px;
    box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
}
.abtbtn a{
    color: white;
    text-decoration: none;

}
.abtbtn a:hover{
    color: white;
}
@media (max-width:900px){
    .abt{
        padding-left: 12px;
        padding-right: 12px;
    }
    
}