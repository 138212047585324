
.navbar{
  background: white;
  height: 80px;
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 999;
  padding-top: 0rem !important;
}
 .active{
  position: sticky !important;
  top: 0;
  z-index: 999;
  box-shadow: 1px 1px 10px #283550;

 background: white;
  /* background: #302d4a !important; */
}
 /* .active ul li a{
  color: white !important;
} */
label.logo{
  color: #283550;
  font-size: 35px;
  line-height: 0px;
  padding: 0 150px;
  text-decoration: none;
  font-weight: bold;
}
.navbar ul{

  float: right;
  margin-right: 120px;
}
.navbar ul li{
    
  display: inline-block;
  line-height: 80px;
  margin: 0 5px;
}
.navbar ul li a{
  text-decoration: none;
  color: #283550;
  font-size: 17px;
  font-weight: 500;
  padding: 7px 13px;
  border-radius: 3px;
}
a:hover{
  color: #283550;
  font-weight: 500;
  transition: .5s;
}
.navbar i{
  color: black;
}
.checkbtn{
  font-size: 30px;
  color: white;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}
#check{
  display: none;
}
@media (max-width: 952px){
  label.logo{
    font-size: 30px;
    
    padding-left: 50px;
  }
  .navbar ul li a{
    font-size: 16px;
  }
}
@media (max-width: 858px){
  .checkbtn{
    padding-left: 15px;
    display: block;
  }
  ul{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgb(230, 227, 227);
    color: black;
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all .5s;
  }
  .navbar ul li{
    display: block;
    margin: 50px 0;
    line-height: 30px;
  }
  .navbar ul li a{
    font-size: 20px;
  }
  a:hover,a.active{
    background: none;
    color: #0082e6;
  }
  #check:checked ~ ul{
    left: 0;
  }
}
.logo img{
  width: 120px;
  height: 70px;
}
@media (max-width:900px){
  .logo img{
    width: 100px;
    height: 50px;
  }
}