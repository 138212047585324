.heading{
    font-weight: bold;
    font-size: 46px;
    padding-top: 50px;
    font-family: 'Montserrat';
}
.banner{
    padding-left: 55px;
    padding-right: 55px;
}
.heading-2{
    color: #474747;
    
    font-size: 20px;
    padding-bottom: 20px;
}
.banner{
    font-family: 'Montserrat';
}
.btntext{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 28px;
    padding-left: 28px;
    border: 1px solid;
    border-radius: 40px;
    color: white;
    background-color: #283550;
    font-size: 20px;
}
.btntext a{
    color: white;
text-decoration: none;
}
.btntext a:hover{
    color: white;
    background-color: #283550;

}
.banner .row{
    padding-left: 105px;
    padding-right: 105px;
}

@media  (max-width: 900px) {
   .banner{
    padding-left: 5px;
    padding-right: 5px;
}
.banner .row{
    padding-left: 20px;
  text-align: center;
    padding-right:20px;
}
.heading{
    font-weight: bold;
    font-size: 36px;
    padding-top: 0px !important;
    font-family: 'Montserrat';
}
    }