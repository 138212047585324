body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.apps{
    padding: 0px !important;
}
.heading{
    font-weight: bold;
    font-size: 46px;
    padding-top: 50px;
    font-family: 'Montserrat';
}
.banner{
    padding-left: 55px;
    padding-right: 55px;
}
.heading-2{
    color: #474747;
    
    font-size: 20px;
    padding-bottom: 20px;
}
.banner{
    font-family: 'Montserrat';
}
.btntext{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 28px;
    padding-left: 28px;
    border: 1px solid;
    border-radius: 40px;
    color: white;
    background-color: #283550;
    font-size: 20px;
}
.btntext a{
    color: white;
text-decoration: none;
}
.btntext a:hover{
    color: white;
    background-color: #283550;

}
.banner .row{
    padding-left: 105px;
    padding-right: 105px;
}

@media  (max-width: 900px) {
   .banner{
    padding-left: 5px;
    padding-right: 5px;
}
.banner .row{
    padding-left: 20px;
  text-align: center;
    padding-right:20px;
}
.heading{
    font-weight: bold;
    font-size: 36px;
    padding-top: 0px !important;
    font-family: 'Montserrat';
}
    }
.footers{
    padding-left: 85px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 85px;
    background: #e5e7eb;
    color: #283550;
}
.footer_2 ul li{
    text-decoration: none;
    list-style: none;
    padding: 0px;
}
.foot_opt2{
    margin-top: -6px;
}
.footer_2 a{
    color: #283550;
    text-decoration: none;
}
.foot_opt3 {
    margin-top: -4px;
}
.foot_opt4{
    margin-top: -4px;
}
.footer_2 span{
    font-size: 30px;
    padding: 7px;
}

@media (max-width:900px){
    .footers{
        padding-left: 15px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 15px;
        background: #e5e7eb;
        color: #283550;
    }
    .footer_2 span{
        font-size: 30px;
        padding: 7px;
    }
}

.navbar{
  background: white;
  height: 80px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999;
  padding-top: 0rem !important;
}
 .active{
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 999;
  box-shadow: 1px 1px 10px #283550;

 background: white;
  /* background: #302d4a !important; */
}
 /* .active ul li a{
  color: white !important;
} */
label.logo{
  color: #283550;
  font-size: 35px;
  line-height: 0px;
  padding: 0 150px;
  text-decoration: none;
  font-weight: bold;
}
.navbar ul{

  float: right;
  margin-right: 120px;
}
.navbar ul li{
    
  display: inline-block;
  line-height: 80px;
  margin: 0 5px;
}
.navbar ul li a{
  text-decoration: none;
  color: #283550;
  font-size: 17px;
  font-weight: 500;
  padding: 7px 13px;
  border-radius: 3px;
}
a:hover{
  color: #283550;
  font-weight: 500;
  transition: .5s;
}
.navbar i{
  color: black;
}
.checkbtn{
  font-size: 30px;
  color: white;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}
#check{
  display: none;
}
@media (max-width: 952px){
  label.logo{
    font-size: 30px;
    
    padding-left: 50px;
  }
  .navbar ul li a{
    font-size: 16px;
  }
}
@media (max-width: 858px){
  .checkbtn{
    padding-left: 15px;
    display: block;
  }
  ul{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgb(230, 227, 227);
    color: black;
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all .5s;
  }
  .navbar ul li{
    display: block;
    margin: 50px 0;
    line-height: 30px;
  }
  .navbar ul li a{
    font-size: 20px;
  }
  a:hover,a.active{
    background: none;
    color: #0082e6;
  }
  #check:checked ~ ul{
    left: 0;
  }
}
.logo img{
  width: 120px;
  height: 70px;
}
@media (max-width:900px){
  .logo img{
    width: 100px;
    height: 50px;
  }
}
.service_row{
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 40px;
    padding-bottom: 50px;
}
.service_row .row{
    box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
    border: 2px solid white;
    padding-left: 30px;
    border-radius: 25px;
    padding-right: 30px;
}
.second {
    margin-top: 50px;
    margin-top: 50px;
    padding-top: 40px;
    padding-bottom: 50px;
}
.servicebtn{
    background: #283550;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 30px;
}
.servicebtn:hover{
    background: #283550;
    color: white;
}

@media (max-width:900px){
    .service_row{
        padding-left: 40px;
        padding-right: 42px;
        padding-top: 40px;

        padding-bottom: 50px;
    }
    .service_row .row{
        box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
        border: 2px solid white;
        padding-left: 20px;
        border-radius: 25px;
        padding-right: 20px;
    }
}
.why_icon{
    font-size: 50px;
    /* text-align: center; */
    font-weight: bold;    
}
.why{
    padding-top: 50px;
    padding-bottom: 70px;
}
.why_icon i{
    color: #283550;
    /* border: 4px solid; */
    width: 100px;
    padding: 25px;
}
.why_icon i h6{
    color: #283550;
    font-family: 'Pacifico';
}
.why{
    padding-left: 95px;
    padding-right: 95px;
}
@media (max-width:900px){
    .why{
        padding-left: 12px;
        padding-right: 12px;
    }  
}
.abts{
    padding-left: 30px;
    padding-right: 30px;
}
.abtbtn{
    background-color: #283550;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    padding: 12px 30px;
    border-radius: 100px;
    box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
}
.abtbtn a{
    color: white;
    text-decoration: none;

}
.abtbtn a:hover{
    color: white;
}
@media (max-width:900px){
    .abt{
        padding-left: 12px;
        padding-right: 12px;
    }
    
}
.hcontact{
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    background: linear-gradient(90deg,#ffaf3d 50%, #283550  50%);
    object-fit:cover !important;
    background-repeat: no-repeat;
}
.hcontact{
    padding-left: 100px;
    padding-right: 100px;
}
.submit_btn{
    background-color: #ffaf3d;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
}
.bnts{
    padding-top: 20px;
}
.bnts button{
    color: white;
    font-weight: bold;
}
.bnts button:hover{
    color: white;
    font-weight: bold;
}
.quoteform{
    padding-left: 50px;
}
.quote{
    color: white;
}

@media (max-width:900px){
    .hcontact{
        padding-left: 12px;
        padding-right: 12px;
    }
    .quoteform{
        padding-left: 0px;
    }
    .hcontact{
        padding-top: 10px;
        padding-bottom: 20px;
        width: 100%;
        background: linear-gradient(180deg,#ffaf3d 40%, #283550  60%);
        object-fit:cover !important;
        background-repeat: no-repeat;
    }
}
.services_row{
    padding-left: 85px;
    padding-right: 85px;
}
.service-card{
    padding:20px;
    box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
}
.services{
    padding-top: 40px;
    transition: 5s;
    cursor: pointer;
}
.service-card:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    z-index: 2;
}
.hserives{
    padding-bottom: 40px;
    padding-top: 20px;
    margin-bottom: 50px;
    margin-top: 50px;
    background-color: #f8f5fe;
}
@media (max-width:900px){
    .services_row{
        padding-left: 12px;
        padding-right: 12px;
    }
    .service-card{
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 20px;
        padding-top: 20px;
        box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
    }
}
.mainservices{
    background-color: #e5e7eb;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 65px;
    padding-bottom: 65px;
}
.mainrow{
    padding-left: 100px;
    padding-right: 100px;
}
.cardclr{
    background-color: #283550;
    color: white;
    border-radius: 7px;
}
.btnlink a{
    font-weight: 600;
    text-decoration: none;
}
.cardclr{
    padding-left: 18px;
    padding-right: 18px;

 }
@media (max-width:900px){
    .mainservices{
        background-color: #e5e7eb;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 65px;
        padding-bottom: 65px;
    }
   .cardclr{
       padding-left: 18px;
       padding-right: 18px;

    }
    .mainrow{
        padding-left: 20px;
        padding-right: 20px;
    }
    .maincol {
        padding: 10px;
    }
}

.numbers{
    padding-left: 85px;
    padding-right: 85px;
    justify-content: center;
    text-align: center;
}
.number_row{
    /* padding-left: 85px;
    padding-right: 85px;
    top: 1790px;
    /* bottom: -10px; */
 
    /* position: absolute;
    width: 100%; */
    padding-left: 1;

}
.number_txt{
    box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);

    border: 4px solid #ffaf3d;
    border-radius: 20px;
    padding-top:20px ;
    color: white;
    background-color: #283550;
}

.number_txt h1{
    font-size: 70px;
    color: white;
    font-weight:700;
}
.number_txt p{
    color: white;
    font-size: 20px;
    font-weight: 600;
}
.number_txt1{
    border: 4px solid #283550;
    border-radius: 20px;
    padding-top:20px ;
        box-shadow: 4px 4px 10px rgba(142, 173, 240, 0.5);

    color: white;
    background-color: #283550;
}
.number_txt1 h1{
    font-size: 70px;
    color: #283550;
    font-weight:700;
}
.number_txt1 p{
    color: white;
    font-size: 20px;
    font-weight: 600;
}

@media(max-width:900px){
    .numbers{
        padding-left: 12px;
        padding-right: 12px;
        justify-content: center;
        text-align: center;
    }
    .number_txt{
        box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
    
        border: 4px solid #283550;
        border-radius: 20px;
        margin-top:10px ;
        color: white;
        background-color: #283550;
    }
    .number_txt1{
        border: 4px solid #283550;
        border-radius: 20px;
        margin-top:10px ;
            box-shadow: 4px 4px 10px rgba(142, 173, 240, 0.5);
    
        color: white;
        background-color: #283550;
    }
}

.abts{
    background-color: #f8f5fe;
    padding-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 80px;
}
.abt{
    padding-bottom: 85px;
    padding-left: 85px;
    padding-top: 40px;
    padding-right: 85px;
}
.abtsws
{
    background-color: #f8f5fe;
    padding-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 80px;
}
@media (max-width:900px){
    .abt{
        padding-bottom: 85px;
        padding-left: 12px;
        padding-top: 40px;
        padding-right: 12px;
    }
}
.inputWithIcon i{
    position: absolute;
    left: 0;
    top:-3px;
    padding: 10px 25px;
    /* align-items: center; */
    color: #aaa;
}
.inputWithIcon input[type=text]{
    padding-left: 40px;
}
.inputWithIcon input[type=text]:focus{
border-color: #283550(180, 173, 173);
box-shadow: 0 0 8px 0 #283550;
}
.inputWithIcon input[type=text]:focus + .f_icon
f_icon
f_icon
f_icon i{
    color: #283550;
}
.inputWithIcon.inputIconBg .f_icon
f_icon
f_icon
f_icon i{
    background-color: #aaa;
    color: #fff;
    padding: 9px 5px;
    
    border-radius: 4px 0 0 4px;
}
.inputWithIcon.inputIconBg input[type=text]:focus + .f_icon
f_icon
f_icon
f_icon i{
    color: #fff;
    background-color: #283550;
}



/* for email section */

.inputWithIcon input[type=email]{
    padding-left: 50px;
}
.inputWithIcon input[type=email]:focus{
    border-color: #283550;
    box-shadow: 0 0 8px 0 #283550;
    }
    .inputWithIcon input[type=email]:focus + .f_icon
f_icon
f_icon
f_icon i{
        color: #283550;
    }
.inputWithIcon.inputIconBg .f_icon
f_icon
f_icon
f_icon i{
    background-color: #aaa;
    /* color: #fff; */
    padding: 7px 6px;
    border-radius: 4px 0 0 4px;
}
.inputWithIcon.inputIconBg input[type=email]:focus + .f_icon
f_icon
f_icon
f_icon i{
    color: #fff;
    background-color: #283550;
}
.inputWithIcon input{
    border-radius: 20px;
    border-color: #283550;
}
/* Phone Number section */

.inputWithIcon input[type=number]{
    padding-left: 50px;
}
.inputWithIcon input[type=number]:focus{
    border-color: #283550;
    box-shadow: 0 0 8px 0 #283550;
    }
    .inputWithIcon input[type=number]:focus + .f_icon
f_icon
f_icon
f_icon i{
        color: #283550;
    }
.inputWithIcon.inputIconBg .f_icon
f_icon
f_icon
f_icon i{
    background-color: #aaa;
    /* color: #fff; */
    padding: 7px 6px;
    border-radius: 4px 0 0 4px;
}
.inputWithIcon.inputIconBg input[type=number]:focus + .f_icon
f_icon
f_icon
f_icon i{
    color: #fff;
    background-color: #283550;
}
.inputWithIcon input{
    border-radius: 20px;
}


.inputWithIcon textarea{
    border-radius: 20px;
      border-color: #283550;
}
.inputWithIcon textarea:focus{
    border-color: #283550;
    box-shadow: 0 0 8px 0 #283550;
    }

    .contacts_form{
        padding-left: 100px;
        padding-right: 100px;
    }
    .contact_with_us{
        font-size: 50px;
        font-weight: 900px;
    }
/* @media screen (max-width="700px"){
    .contact_form{
        padding: 0%
    
   
    }
} */
.inputWithIcon .placeholder{
    font-size: small;
}
.formbtn{
    border: 2px solid #283550;
}

.formbtn:hover{
    border: 2px solid #283550;
    background-color: #283550;
    color: white;
}


@media (max-width:900px){
    .contacts_form{
        padding-left: 12px;
        padding-right: 12px;
    }
}
.address .fa-paper-plane{
    background: white;
    padding:25px;
    border: white;
    border: 2px solid white;
    color: black;
    box-shadow: 4px 4px 10px rgba(142, 167, 226, 0.5);
    border-radius:50px;
}
.address .fa-phone{
    background: white;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    border: white;
    border: 2px solid white;
    color: black;
    box-shadow: 4px 4px 10px rgba(142, 167, 226, 0.5);
    border-radius:50px;
}

.address .fa-map-marker{
    background: white;
    padding-right: 32px;
    padding-left: 32px;
    padding-top: 25px;
    padding-bottom: 25px;
    border: white;
    border: 2px solid white;
    color: black;
    box-shadow: 4px 4px 10px rgba(142, 167, 226, 0.5);
    border-radius:50px;
}
