.why_icon{
    font-size: 50px;
    /* text-align: center; */
    font-weight: bold;    
}
.why{
    padding-top: 50px;
    padding-bottom: 70px;
}
.why_icon i{
    color: #283550;
    /* border: 4px solid; */
    width: 100px;
    padding: 25px;
}
.why_icon i h6{
    color: #283550;
    font-family: 'Pacifico';
}
.why{
    padding-left: 95px;
    padding-right: 95px;
}
@media (max-width:900px){
    .why{
        padding-left: 12px;
        padding-right: 12px;
    }  
}