.footers{
    padding-left: 85px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 85px;
    background: #e5e7eb;
    color: #283550;
}
.footer_2 ul li{
    text-decoration: none;
    list-style: none;
    padding: 0px;
}
.foot_opt2{
    margin-top: -6px;
}
.footer_2 a{
    color: #283550;
    text-decoration: none;
}
.foot_opt3 {
    margin-top: -4px;
}
.foot_opt4{
    margin-top: -4px;
}
.footer_2 span{
    font-size: 30px;
    padding: 7px;
}

@media (max-width:900px){
    .footers{
        padding-left: 15px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 15px;
        background: #e5e7eb;
        color: #283550;
    }
    .footer_2 span{
        font-size: 30px;
        padding: 7px;
    }
}