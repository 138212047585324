.services_row{
    padding-left: 85px;
    padding-right: 85px;
}
.service-card{
    padding:20px;
    box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
}
.services{
    padding-top: 40px;
    transition: 5s;
    cursor: pointer;
}
.service-card:hover{
    transform: scale(1.05);
    z-index: 2;
}
.hserives{
    padding-bottom: 40px;
    padding-top: 20px;
    margin-bottom: 50px;
    margin-top: 50px;
    background-color: #f8f5fe;
}
@media (max-width:900px){
    .services_row{
        padding-left: 12px;
        padding-right: 12px;
    }
    .service-card{
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 20px;
        padding-top: 20px;
        box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
    }
}