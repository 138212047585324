.service_row{
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 40px;
    padding-bottom: 50px;
}
.service_row .row{
    box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
    border: 2px solid white;
    padding-left: 30px;
    border-radius: 25px;
    padding-right: 30px;
}
.second {
    margin-top: 50px;
    margin-top: 50px;
    padding-top: 40px;
    padding-bottom: 50px;
}
.servicebtn{
    background: #283550;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 30px;
}
.servicebtn:hover{
    background: #283550;
    color: white;
}

@media (max-width:900px){
    .service_row{
        padding-left: 40px;
        padding-right: 42px;
        padding-top: 40px;

        padding-bottom: 50px;
    }
    .service_row .row{
        box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
        border: 2px solid white;
        padding-left: 20px;
        border-radius: 25px;
        padding-right: 20px;
    }
}