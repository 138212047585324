.inputWithIcon i{
    position: absolute;
    left: 0;
    top:-3px;
    padding: 10px 25px;
    /* align-items: center; */
    color: #aaa;
}
.inputWithIcon input[type=text]{
    padding-left: 40px;
}
.inputWithIcon input[type=text]:focus{
border-color: #283550(180, 173, 173);
box-shadow: 0 0 8px 0 #283550;
}
.inputWithIcon input[type=text]:focus + .f_icon
f_icon
f_icon
f_icon i{
    color: #283550;
}
.inputWithIcon.inputIconBg .f_icon
f_icon
f_icon
f_icon i{
    background-color: #aaa;
    color: #fff;
    padding: 9px 5px;
    
    border-radius: 4px 0 0 4px;
}
.inputWithIcon.inputIconBg input[type=text]:focus + .f_icon
f_icon
f_icon
f_icon i{
    color: #fff;
    background-color: #283550;
}



/* for email section */

.inputWithIcon input[type=email]{
    padding-left: 50px;
}
.inputWithIcon input[type=email]:focus{
    border-color: #283550;
    box-shadow: 0 0 8px 0 #283550;
    }
    .inputWithIcon input[type=email]:focus + .f_icon
f_icon
f_icon
f_icon i{
        color: #283550;
    }
.inputWithIcon.inputIconBg .f_icon
f_icon
f_icon
f_icon i{
    background-color: #aaa;
    /* color: #fff; */
    padding: 7px 6px;
    border-radius: 4px 0 0 4px;
}
.inputWithIcon.inputIconBg input[type=email]:focus + .f_icon
f_icon
f_icon
f_icon i{
    color: #fff;
    background-color: #283550;
}
.inputWithIcon input{
    border-radius: 20px;
    border-color: #283550;
}
/* Phone Number section */

.inputWithIcon input[type=number]{
    padding-left: 50px;
}
.inputWithIcon input[type=number]:focus{
    border-color: #283550;
    box-shadow: 0 0 8px 0 #283550;
    }
    .inputWithIcon input[type=number]:focus + .f_icon
f_icon
f_icon
f_icon i{
        color: #283550;
    }
.inputWithIcon.inputIconBg .f_icon
f_icon
f_icon
f_icon i{
    background-color: #aaa;
    /* color: #fff; */
    padding: 7px 6px;
    border-radius: 4px 0 0 4px;
}
.inputWithIcon.inputIconBg input[type=number]:focus + .f_icon
f_icon
f_icon
f_icon i{
    color: #fff;
    background-color: #283550;
}
.inputWithIcon input{
    border-radius: 20px;
}


.inputWithIcon textarea{
    border-radius: 20px;
      border-color: #283550;
}
.inputWithIcon textarea:focus{
    border-color: #283550;
    box-shadow: 0 0 8px 0 #283550;
    }

    .contacts_form{
        padding-left: 100px;
        padding-right: 100px;
    }
    .contact_with_us{
        font-size: 50px;
        font-weight: 900px;
    }
/* @media screen (max-width="700px"){
    .contact_form{
        padding: 0%
    
   
    }
} */
.inputWithIcon .placeholder{
    font-size: small;
}
.formbtn{
    border: 2px solid #283550;
}

.formbtn:hover{
    border: 2px solid #283550;
    background-color: #283550;
    color: white;
}


@media (max-width:900px){
    .contacts_form{
        padding-left: 12px;
        padding-right: 12px;
    }
}
.address .fa-paper-plane{
    background: white;
    padding:25px;
    border: white;
    border: 2px solid white;
    color: black;
    box-shadow: 4px 4px 10px rgba(142, 167, 226, 0.5);
    border-radius:50px;
}
.address .fa-phone{
    background: white;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    border: white;
    border: 2px solid white;
    color: black;
    box-shadow: 4px 4px 10px rgba(142, 167, 226, 0.5);
    border-radius:50px;
}

.address .fa-map-marker{
    background: white;
    padding-right: 32px;
    padding-left: 32px;
    padding-top: 25px;
    padding-bottom: 25px;
    border: white;
    border: 2px solid white;
    color: black;
    box-shadow: 4px 4px 10px rgba(142, 167, 226, 0.5);
    border-radius:50px;
}