.numbers{
    padding-left: 85px;
    padding-right: 85px;
    justify-content: center;
    text-align: center;
}
.number_row{
    /* padding-left: 85px;
    padding-right: 85px;
    top: 1790px;
    /* bottom: -10px; */
 
    /* position: absolute;
    width: 100%; */
    padding-left: 1;

}
.number_txt{
    box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);

    border: 4px solid #ffaf3d;
    border-radius: 20px;
    padding-top:20px ;
    color: white;
    background-color: #283550;
}

.number_txt h1{
    font-size: 70px;
    color: white;
    font-weight:700;
}
.number_txt p{
    color: white;
    font-size: 20px;
    font-weight: 600;
}
.number_txt1{
    border: 4px solid #283550;
    border-radius: 20px;
    padding-top:20px ;
        box-shadow: 4px 4px 10px rgba(142, 173, 240, 0.5);

    color: white;
    background-color: #283550;
}
.number_txt1 h1{
    font-size: 70px;
    color: #283550;
    font-weight:700;
}
.number_txt1 p{
    color: white;
    font-size: 20px;
    font-weight: 600;
}

@media(max-width:900px){
    .numbers{
        padding-left: 12px;
        padding-right: 12px;
        justify-content: center;
        text-align: center;
    }
    .number_txt{
        box-shadow: 4px 4px 10px rgb(0 79 255 / 50%);
    
        border: 4px solid #283550;
        border-radius: 20px;
        margin-top:10px ;
        color: white;
        background-color: #283550;
    }
    .number_txt1{
        border: 4px solid #283550;
        border-radius: 20px;
        margin-top:10px ;
            box-shadow: 4px 4px 10px rgba(142, 173, 240, 0.5);
    
        color: white;
        background-color: #283550;
    }
}
