.mainservices{
    background-color: #e5e7eb;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 65px;
    padding-bottom: 65px;
}
.mainrow{
    padding-left: 100px;
    padding-right: 100px;
}
.cardclr{
    background-color: #283550;
    color: white;
    border-radius: 7px;
}
.btnlink a{
    font-weight: 600;
    text-decoration: none;
}
.cardclr{
    padding-left: 18px;
    padding-right: 18px;

 }
@media (max-width:900px){
    .mainservices{
        background-color: #e5e7eb;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 65px;
        padding-bottom: 65px;
    }
   .cardclr{
       padding-left: 18px;
       padding-right: 18px;

    }
    .mainrow{
        padding-left: 20px;
        padding-right: 20px;
    }
    .maincol {
        padding: 10px;
    }
}
