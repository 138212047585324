.abts{
    background-color: #f8f5fe;
    padding-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 80px;
}
.abt{
    padding-bottom: 85px;
    padding-left: 85px;
    padding-top: 40px;
    padding-right: 85px;
}
.abtsws
{
    background-color: #f8f5fe;
    padding-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 80px;
}
@media (max-width:900px){
    .abt{
        padding-bottom: 85px;
        padding-left: 12px;
        padding-top: 40px;
        padding-right: 12px;
    }
}